function getResourceSize(resourceSizes: any, type: string) {
  const foundResource = resourceSizes.filter((resource: any) => {
    return resource.resourceType === type;
  });
  
  return foundResource.length ? foundResource[0].size / 1000 : 0;
}

export default function parseLighthouseBudget(resourceSizes: any) {
  return {
      javascript: getResourceSize(resourceSizes, 'script'),
      css: getResourceSize(resourceSizes, 'stylesheet'),
      images: getResourceSize(resourceSizes, 'image'),
      video: getResourceSize(resourceSizes, 'media'),
      fonts: getResourceSize(resourceSizes, 'font'),
      html: getResourceSize(resourceSizes, 'document'),
      total: getResourceSize(resourceSizes, 'total'),
  };
}