import React from 'react';
// import clsx from 'clsx';
import SubHeader from '../../components/SubHeader';
import { makeStyles } from '@material-ui/core/styles';
import { useIdentityContext } from 'react-netlify-identity-widget';
import Paper from '@material-ui/core/Paper';
import useRequest from '../../utils/request';
import { Grid } from '@material-ui/core';
import { Pie, HorizontalBar } from 'react-chartjs-2';
import lighthouseParser from '../../utils/lighthouse-budget-parser';

const useStyles = makeStyles(theme => ({
  root: {
    flex: 1,
    background: '#eaeff1',
  },
  paddingBlock: {
    padding: theme.spacing(2, 3),
  },
  content: {
    padding: theme.spacing(6, 4),
  },
  paper: {
    margin: '0 10px 20px',
    overflow: 'hidden',
    maxWidth: 936,
  },
  paperHeading: {
    margin: 0,
    padding: '10px',
    background: '#eaebeb',
    fontSize: '1.1rem',
  },
  searchBar: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  searchInput: {
    fontSize: theme.typography.fontSize,
  },
  block: {
    display: 'block',
  },
  addUser: {
    marginRight: theme.spacing(1),
  },
  contentWrapper: {
    margin: '10px 20px 10px',
  },
  contentText: {
    marginBottom: '0'
  },
  table: {
    
  },
  tableIcon: {
    float: 'right',
    width: '30px',
  },
  tableIconGreen: {
    color: '#00ff00',
  },
  tableIconRed: {
    color: '#ff0000',
  },
  latestTestImage: {
    margin: '20px',
    border: '1px solid #999',
    borderRadius: '5px',
  }
}));

export interface TestsProps {
  match: any;
}

export default function Tests(props: TestsProps) {
  const classes = useStyles(props);
  const { siteId } = props.match.params;
  const identity = useIdentityContext();
  const userId = identity.user && identity.user.id;
  const [site, setSite] = React.useState();

  const request = useRequest();

  React.useEffect(() => {
    request(`/user/${userId}/site/${siteId}`).then(res => res.json()).then((siteData) => {
      console.log(siteData);
      setSite(siteData);
    });
  }, [siteId, userId, identity]);

  function formatData(data: any) {
    const parsedData = lighthouseParser(data);

    return {
      labels: ['Script', 'Stylesheet', 'Image', 'Document', 'Media', 'Font'],
      datasets: [{
          data: [
            parsedData.javascript,
            parsedData.css,
            parsedData.images,
            parsedData.html,
            parsedData.video,
            parsedData.fonts,
          ],
          backgroundColor: [
              'rgba(255, 99, 132, 0.2)',
              'rgba(54, 162, 235, 0.2)',
              'rgba(255, 206, 86, 0.2)',
              'rgba(75, 192, 192, 0.2)',
              'rgba(153, 102, 255, 0.2)',
              'rgba(255, 159, 64, 0.2)',
              'rgba(100, 159, 64, 0.2)'
          ],
          borderWidth: 2
      }]
    };
  }
  // <p>First Contentful Paint: {site.latestTest.audits['first-contentful-paint'].displayValue} </p>
  // <p>First Meaningful Paint: {site.latestTest.audits['first-meaningful-paint'].displayValue} </p>
  // <p>Time to interative: {site.latestTest.audits['interactive'].displayValue} </p>
  // <p>Bootup time: {site.latestTest.audits['bootup-time'].displayValue} </p>
  // <p>Total Network Requests: {site.latestTest.audits["network-requests"].details.items.length} </p>

  function formatMetrics() {
    return {
      labels: ['Bootup time', 'First Contentful Paint', 'First Meaningful Paint', 'Time to interative'],
      datasets: [{
          data: [
            site.latestTest.audits['bootup-time'].displayValue.replace('s', ''),
            site.latestTest.audits['first-contentful-paint'].displayValue.replace('s', ''),
            site.latestTest.audits['first-meaningful-paint'].displayValue.replace('s', ''),
            site.latestTest.audits['interactive'].displayValue.replace('s', ''),
          ],
          backgroundColor: [
              'rgba(255, 99, 132, 0.2)',
              'rgba(54, 162, 235, 0.2)',
              'rgba(255, 206, 86, 0.2)',
              'rgba(75, 192, 192, 0.2)',
          ],
          borderWidth: 2,
          label: 'seconds',
      }]
    };
  }

  return (
    <div className={classes.root}>
      {site && site.name !== '' && <div>
        <SubHeader title={`Site: ${site.name}`} tab={0} siteId={siteId} />
        <main className={classes.content}>
          {site && site.latestTest && <div>
            {/* <Paper className={classes.paper}>
              <h2 className={classes.paperHeading}>Most Recent Test {site && site.latestTest.testDate}</h2>
              {site && <Grid container>
                <Grid>
                  <img className={classes.latestTestImage} src={site.latestTest.audits['final-screenshot'].details.data} width="200px" />
                </Grid>
                <Grid>
                  <p>First Contentful Paint: {site.latestTest.audits['first-contentful-paint'].displayValue} </p>
                  <p>First Meaningful Paint: {site.latestTest.audits['first-meaningful-paint'].displayValue} </p>
                  <p>Time to interative: {site.latestTest.audits['interactive'].displayValue} </p>
                  <p>Bootup time: {site.latestTest.audits['bootup-time'].displayValue} </p>
                  <p>Total Network Requests: {site.latestTest.audits["network-requests"].details.items.length} </p>
                </Grid>
              </Grid>}
            </Paper> */}

            {site && <Grid container>
              <Grid item xs>
                <Paper className={classes.paper}>
                  <h2 className={classes.paperHeading}>Site Metrics</h2>
                  <Grid className={classes.paddingBlock}>
                  <HorizontalBar
                      data={formatMetrics()}
                      width={300}
                      height={400}
                      options={{ maintainAspectRatio: false, legend: {display: false} }}
                    />
                  </Grid>
                </Paper>
              </Grid>
              <Grid item xs>
                <Paper className={classes.paper}>
                  <h2 className={classes.paperHeading}>Assets</h2>
                  <Grid className={classes.paddingBlock}>
                  <Pie
                      data={formatData(site.latestTest.audits["resource-summary"].details.items)}
                      width={300}
                      height={400}
                      options={{ maintainAspectRatio: false }}
                    />
                  </Grid>
                </Paper>
              </Grid>
            </Grid>}
          </div>}
          
          {site && !site.latestTest && <div>
            Waiting for results
          </div>}
          
        </main>
      </div>}
    </div>
  );
}