import React from 'react';
import SubHeader from '../components/SubHeader';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    flex: 1,
    background: '#eaeff1',
  },
  content: {
    padding: theme.spacing(6, 4),
  }
}));

export interface LoggedOutProps {
}

function Site(props: LoggedOutProps) {
  const classes = useStyles(props);

  return (
    <div className={classes.root}>
      <SubHeader title="Billing" showMenu={false}/>
      <main className={classes.content}>
        <p>PerfBadger is free while we are in alpha stages, we will email you once its out of beta.</p>
      </main>
    </div>
  );
}

export default Site;