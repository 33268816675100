import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { createMuiTheme, createStyles, withStyles, WithStyles } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Hidden from '@material-ui/core/Hidden';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import Navigator from '../components/Navigator';
import Report from '../pages/site/Report';
import Tests from '../pages/site/Tests';
import Setup from '../pages/site/Setup';
import Home from '../pages/Home';
import NewSite from '../pages/NewSite';
import Billing from '../pages/Billing';
import Jobs from '../pages/admin/Jobs';
import Users from '../pages/admin/Users';
import Sites from '../pages/admin/Sites';
import Header from '../components/Header';
import { useIdentityContext } from 'react-netlify-identity-widget';

import useRequest from '../utils/request';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://www.perfbadget.com/">
        PerfBadger
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

let theme = createMuiTheme({
  palette: {
    primary: {
      light: '#63ccff',
      main: '#009be5',
      dark: '#006db3',
    },
  },
  typography: {
    h5: {
      fontWeight: 500,
      fontSize: 26,
      letterSpacing: 0.5,
    },
  },
  shape: {
    borderRadius: 8,
  },
  props: {
    MuiTab: {
      disableRipple: true,
    },
  },
  mixins: {
    toolbar: {
      minHeight: 48,
    },
  },
});

theme = {
  ...theme,
  overrides: {
    MuiDrawer: {
      paper: {
        backgroundColor: '#18202c',
      },
    },
    MuiButton: {
      label: {
        textTransform: 'none',
      },
      contained: {
        boxShadow: 'none',
        '&:active': {
          boxShadow: 'none',
        },
      },
    },
    MuiTabs: {
      root: {
        marginLeft: theme.spacing(1),
      },
      indicator: {
        height: 3,
        borderTopLeftRadius: 3,
        borderTopRightRadius: 3,
        backgroundColor: theme.palette.common.white,
      },
    },
    MuiTab: {
      root: {
        textTransform: 'none',
        margin: '0 16px',
        minWidth: 0,
        padding: 0,
        [theme.breakpoints.up('md')]: {
          padding: 0,
          minWidth: 0,
        },
      },
    },
    MuiIconButton: {
      root: {
        padding: theme.spacing(1),
      },
    },
    MuiTooltip: {
      tooltip: {
        borderRadius: 4,
      },
    },
    MuiDivider: {
      root: {
        backgroundColor: '#404854',
      },
    },
    MuiListItemText: {
      primary: {
        fontWeight: theme.typography.fontWeightMedium,
      },
    },
    MuiListItemIcon: {
      root: {
        color: 'inherit',
        marginRight: 0,
        '& svg': {
          fontSize: 20,
        },
      },
    },
    MuiAvatar: {
      root: {
        width: 32,
        height: 32,
      },
    },
  },
};

const drawerWidth = 256;

const styles = createStyles({
  root: {
    display: 'flex',
    minHeight: '100vh',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  app: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  main: {
    flex: 1,
    padding: theme.spacing(6, 4),
    background: '#eaeff1',
  },
  footer: {
    padding: theme.spacing(2),
    background: '#eaeff1',
  },
});

export interface LoggedInProps extends WithStyles<typeof styles> {}

function LoggedIn(props: LoggedInProps) {
  const { classes } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [sites, setSites] = React.useState([]);
  const identity = useIdentityContext();
  const userId = identity.user && identity.user.id;

  const request = useRequest();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  function updateSite() {
    request(`/user/${userId}/sites`).then(res => res.json()).then(setSites);
  }

  React.useEffect(() => {
    updateSite();
  }, [userId, identity]);

  return (
    <ThemeProvider theme={theme}>
      <Router>
      <div className={classes.root}>
        <CssBaseline />
        <nav className={classes.drawer}>
          <Hidden smUp implementation="js">
            <Navigator
              PaperProps={{ style: { width: drawerWidth } }}
              variant="temporary"
              open={mobileOpen}
              onClose={handleDrawerToggle}
              sites={sites}
            />
          </Hidden>
          <Hidden xsDown implementation="css">
            <Navigator
              PaperProps={{ style: { width: drawerWidth } }}
              sites={sites}
            />
          </Hidden>
        </nav>
        <div className={classes.app}>
          <Header onDrawerToggle={handleDrawerToggle} />
          <Route path='/' exact component={Home} />
          <Route path='/new-site' render={(props) => <NewSite {...props} onCreate={updateSite} />} />
          <Route path='/site/:siteId' component={Report} exact />
          <Route path='/site/:siteId/tests' component={Tests} />
          <Route path='/site/:siteId/setup'  render={(props) => <Setup {...props} onDelete={updateSite} />}/>
          <Route path='/billing' component={Billing} />
          <Route path='/internal/jobs' component={Jobs} />
          <Route path='/internal/users' component={Users} />
          <Route path='/internal/sites' component={Sites} />
          <footer className={classes.footer}>
            <Copyright />
          </footer>
        </div>
      </div>
      </Router>
    </ThemeProvider>
  );
}

export default withStyles(styles)(LoggedIn);
