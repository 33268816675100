import React from 'react';
import clsx from 'clsx';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Drawer, { DrawerProps } from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import DollarIcon from '@material-ui/icons/AttachMoney';
import JobsIcon from '@material-ui/icons/QueryBuilder';
import UserIcon from '@material-ui/icons/PermIdentity';
import WebAssetIcon from '@material-ui/icons/WebAsset';
import AddIcon from '@material-ui/icons/Add';
import { Omit } from '@material-ui/types';
import logo from '../logo.svg'
import { NavLink } from 'react-router-dom';
import { useIdentityContext } from 'react-netlify-identity-widget'

const adminCategories: any[] = [
  // {
  //   id: 'Admin',
  //   children: [
  //     { id: 'Billing', icon: <DollarIcon />, href:'/billing' },
  //   ],
  // },
];

const badgerCategories = [
  {
    id: 'Internal',
    children: [
      { id: 'Users', icon: <UserIcon />, href:'/internal/users' },
      { id: 'Sites', icon: <WebAssetIcon />, href:'/internal/sites' },
      { id: 'Scheduled Jobs', icon: <JobsIcon />, href:'/internal/jobs' },
    ],
  },
];


const styles = (theme: Theme) =>
  createStyles({
    logo: {
      maxWidth: '100%',
    },
    categoryHeader: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
    categoryHeaderPrimary: {
      color: theme.palette.common.white,
    },
    item: {
      paddingTop: 1,
      paddingBottom: 1,
      color: 'rgba(255, 255, 255, 0.7)',
      '&:hover,&:focus': {
        backgroundColor: 'rgba(255, 255, 255, 0.08)',
      },
    },
    itemCategory: {
      backgroundColor: '#232f3e',
      boxShadow: '0 -1px 0 #404854 inset',
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
    firebase: {
      fontSize: 24,
      color: theme.palette.common.white,
    },
    itemActiveItem: {
      color: '#4fc3f7',
    },
    itemPrimary: {
      fontSize: 'inherit',
      color: '#fff',
    },
    link: {
      textDecoration: 'none',
    },
    itemIcon: {
      minWidth: 'auto',
      marginRight: theme.spacing(2),
    },
    divider: {
      marginTop: theme.spacing(2),
    },
  });

export interface NavigatorProps extends Omit<DrawerProps, 'classes'>, WithStyles<typeof styles> {
  sites: any;
}

function Navigator(props: NavigatorProps) {
  const identity = useIdentityContext();
  const isAdmin = identity && identity.user && identity.user.app_metadata && identity.user.app_metadata.roles && identity.user.app_metadata.roles.includes('admin');
  const isBadgerAdmin = identity && identity.user && identity.user.app_metadata && identity.user.app_metadata.roles && identity.user.app_metadata.roles.includes('badger-admin');

  const sites = props.sites.map((site: any) => {
    return {
      id: site.name,
      icon: <WebAssetIcon />,
      href: `/site/${site.id}`
    };
  })

  const normalCategories = [
    {
      id: 'Dashboards',
      children: [
        { id: 'Home', icon: <WebAssetIcon />, href: '' }
      ],
    },
    {
      id: 'Sites',
      children: [
        ...sites,
        { id: '', href:'' },
        { id: 'New Site', icon: <AddIcon />, href:'/new-site' },
      ],
    },
  ];

  const isAdminCategories = isAdmin ? normalCategories.concat(adminCategories) : normalCategories;
  const categories = isBadgerAdmin ? isAdminCategories.concat(badgerCategories) : isAdminCategories;

  const { classes, ...other } = props;

  return (
    <Drawer variant="permanent" {...other}>
      <List disablePadding>
        <ListItem className={clsx(classes.firebase, classes.item, classes.itemCategory)}>
          <img src={logo} alt="Perfbadger" className={classes.logo} />
        </ListItem>
        {categories.map(({ id, children }) => (
          <React.Fragment key={id}>
            <ListItem className={classes.categoryHeader}>
              <ListItemText
                classes={{
                  primary: classes.categoryHeaderPrimary,
                }}
              >
                {id}
              </ListItemText>
            </ListItem>
            {children.map(({ id: childId, icon, href }) => (
              <ListItem
                key={childId}
                button
                className={clsx(classes.item)}
              >
                {icon && <ListItemIcon className={classes.itemIcon}>{icon}</ListItemIcon>}
                <NavLink to={href} className={classes.link} activeClassName={classes.itemActiveItem}>
                  <ListItemText
                    classes={{
                      primary: classes.itemPrimary,
                    }}
                  >
                    {childId}
                  </ListItemText>
                </NavLink>
            
              </ListItem>
            ))}
            <Divider className={classes.divider} />
          </React.Fragment>
        ))}
      </List>
    </Drawer>
  );
}

export default withStyles(styles)(Navigator);
