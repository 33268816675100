import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { blue } from '@material-ui/core/colors';

const useStyles = makeStyles(theme => ({
  root: {
    textAlign: 'center',
  },
  button: {
    margin: '0 5px',
    position: 'relative',
  },
  buttonProgress: {
    color: blue[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

interface ButtonBarProps {
  onClick: any,
  text: string,
  loading: boolean,
 }

const ButtonBar: React.FC<ButtonBarProps> = (props) => {
  const classes = useStyles(props);

  function click() {
    if (!props.loading) {
      props.onClick();
    }
  }

  return (
    <Button className={classes.button} variant="contained" color="primary" disabled={props.loading} onClick={click}>
      {props.text}
      {props.loading && <CircularProgress size={24} className={classes.buttonProgress} />}
    </Button>
  );
}

export default ButtonBar;