import React from 'react';
import SubHeader from '../components/SubHeader';
import { makeStyles } from '@material-ui/core/styles';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import SubmitButton from '../components/SubmitButton';
import { useIdentityContext } from 'react-netlify-identity-widget';
import { RouteComponentProps } from "react-router-dom";
import useRequest from '../utils/request';

const useStyles = makeStyles(theme => ({
  root: {
    flex: 1,
    background: '#eaeff1',
  },
  content: {
    padding: theme.spacing(6, 4),
  },
  paper: {
    maxWidth: 936,
    margin: '0 auto 20px',
    overflow: 'hidden',
  },
  searchBar: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  searchInput: {
    fontSize: theme.typography.fontSize,
  },
  block: {
    display: 'block',
  },
  addUser: {
    marginRight: theme.spacing(1),
  },
  contentWrapper: {
    margin: '10px 20px 10px',
  },
  contentText: {
    marginBottom: '0'
  }
}));

export interface NewSiteProps extends RouteComponentProps {
  onCreate: Function,
}

function NewSite(props: NewSiteProps) {
  const classes = useStyles(props);
  const [url, setUrl] = React.useState();
  const [submitting, setSubmitting] = React.useState(false);
  const identity = useIdentityContext();
  const userId = identity.user && identity.user.id;
  const request = useRequest();
  const [error, setError] = React.useState();
  
  function updateUrl(event: any) {
    setUrl(event.target.value);
  }

  function createSite() {
    if (submitting === true) {
      return;
    }

    setSubmitting(true);

    request(`/user/${userId}/sites`, {
      method: 'post',
      body: JSON.stringify({
          url,
      }),
      headers: { 'Content-Type': 'application/json' },
    }).then(res => res.json()).then((response) => {
      setSubmitting(false);
      
      if (response.errorMessage) {
        setError(response.errorMessage);
      } else {
        props.onCreate();
        props.history.push(`/site/${response.id}`);
      }
    });
  }

  return (
    <div className={classes.root}>
      <SubHeader title="New Site" showMenu={false}/>
      <main className={classes.content}>
        <Paper className={classes.paper}>
          <AppBar className={classes.searchBar} position="static" color="default" elevation={0}>
            <Toolbar>
              <Grid container spacing={2} alignItems="center">
                <Grid item xs>
                  <TextField
                    fullWidth
                    placeholder="Website URL"
                    defaultValue={url}
                    onChange={updateUrl}
                    InputProps={{
                      disableUnderline: true,
                      className: classes.searchInput,
                    }}
                  />
                </Grid>
                <Grid item>
                  <SubmitButton onClick={createSite} loading={submitting} text='Add site' />
                </Grid>
              </Grid>
            </Toolbar>
          </AppBar>
        </Paper>
        {error && <p>{error}</p>}
      </main>
    </div>
  );
}

export default NewSite;