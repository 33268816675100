import React from 'react';
import SubHeader from '../components/SubHeader';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles(theme => ({
  root: {
    flex: 1,
    background: '#eaeff1',
  },
  content: {
    padding: theme.spacing(6, 4),
  },
  paper: {
    padding: theme.spacing(2, 2),
  }
}));

export interface LoggedOutProps {
}

function Site(props: LoggedOutProps) {
  const classes = useStyles(props);

  return (
    <div className={classes.root}>
      <SubHeader title="Home" showMenu={false} />
      <main className={classes.content}>
        <Grid container spacing={1} alignItems="center">
          <Grid item xs>
            <Paper className={classes.paper}>
              Hello
            </Paper>    
          </Grid>

          <Grid item xs>
            <Paper className={classes.paper}>
              Hello
            </Paper>    
          </Grid>

          <Grid item xs>
            <Paper className={classes.paper}>
              Hello
            </Paper>    
          </Grid>

          <Grid item xs>
            <Paper className={classes.paper}>
              Hello
            </Paper>    
          </Grid>
        </Grid>
      </main>
    </div>
  );
}

export default Site;