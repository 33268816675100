import { useIdentityContext } from 'react-netlify-identity-widget';
const apiUrl = 'https://perfbadger-api.jonthanfielding.com';

export default function useRequest() {
  const identity = useIdentityContext();

  return async function(url: string, options: any = {}) {
    let accessToken = window.sessionStorage.getItem('PB_ACCESS_TOKEN');
    if (!accessToken) {
      
      if (identity.user && identity.user.jwt) {
        const jwt = await identity.user.jwt();
  
        const authRequest = await window.fetch(`${apiUrl}/auth`, {
          "method": "POST",
          "headers": {
            "Content-Type": "application/json; charset=utf-8"
          },
          "body": JSON.stringify({
            jwt,
          })
        }).then(res => res.text());
    
        if (authRequest !== 'Unauthorized') {
          accessToken = authRequest;
          window.sessionStorage.setItem('PB_ACCESS_TOKEN', accessToken);
        }
      }
    }
    
    const headers = {
      ...options.headers,
      "Authorization": `token ${accessToken}`
    };
  
    return fetch(`${apiUrl}${url}`, {
      ...options,
      headers,
    });
  }
}