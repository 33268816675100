import React from 'react';
import SubHeader from '../../components/SubHeader';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    flex: 1,
    background: '#eaeff1',
  },
  content: {
    padding: theme.spacing(6, 4),
  },
  paper: {
    padding: theme.spacing(2, 2),
  }
}));

export interface UsersProps {
}

function Users(props: UsersProps) {
  const classes = useStyles(props);

  return (
    <div className={classes.root}>
      <SubHeader title="Users" showMenu={false} />
      <main className={classes.content}>
        
      </main>
    </div>
  );
}

export default Users;