import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  beta: {
    padding: '10px',
    background: '#ddd',
    textAlign: 'center',
  },
  globalHeader: {
    minHeight: '200px',
    textAlign: 'center',
    backgroundColor: '#0275d8',
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingBottom: '10px',
    marginBottom: '20px',
    [theme.breakpoints.up('md')]: {
      minHeight: '10vh',
      marginBottom: '30px',
    },
  },
  globalHeaderTitle: {
    color: '#fff',
    [theme.breakpoints.up('md')]: {
      color: '#fff',
      marginBottom: '0'
    },
  },
  globalHeaderNavList: {
    listStyle: 'none',
    paddingLeft: 0,
  },
  globalHeaderNavItem: {
    display: 'inline-block',
    padding: '0 20px',
    '& a': {
      color: '#fff',
    }
  },
  logo: {
    height: '50px',
  },
}));

const Header: React.FC = (props) => {
  const classes = useStyles(props);

  return (
    <div>
      <header className={classes.globalHeader}>
        <div>
          <h1 className={classes.globalHeaderTitle}>
            <img src="/logo.png" alt="PerfBadger" className={classes.logo}/>
          </h1>
        </div>
    </header>
    </div>
  );
}

export default Header;
        