import React from 'react'
import { useIdentityContext, IdentityContextProvider } from 'react-netlify-identity-widget'
import 'react-netlify-identity-widget/styles.css'
import {IntlProvider} from 'react-intl';


import LoggedIn from './layouts/LoggedIn';
import LoggedOut from './layouts/LoggedOut';

function App() {
  const url = 'https://www.perfbadger.com' // should look something like "https://foo.netlify.com"
  
  return (
    <IdentityContextProvider url={url}>
      <AuthStatusView />
    </IdentityContextProvider>
  )
}

function getLang(){
  if (navigator.languages != undefined) {
    return navigator.languages[0];
  } else {
    return navigator.language;
  }
}


function AuthStatusView() {
  const identity = useIdentityContext();
  return (
    <IntlProvider locale={getLang()}>
      <div className="App">
        <header className="App-header">
          {identity && identity.isLoggedIn ? (
            <>
              <LoggedIn></LoggedIn>
            </>
          ) : (
            <>
              <LoggedOut></LoggedOut>
            </>
          )}
        </header>
      </div>
    </IntlProvider>
  )
}
export default App
