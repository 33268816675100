import React from 'react';
import clsx from 'clsx';
import SubHeader from '../../components/SubHeader';
import { makeStyles } from '@material-ui/core/styles';
import { useIdentityContext } from 'react-netlify-identity-widget';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import Button from '@material-ui/core/Button';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import ArrowUp from '@material-ui/icons/ArrowDropUp';
import ArrowDown from '@material-ui/icons/ArrowDropDown';
import Level from '@material-ui/icons/Remove';
import useRequest from '../../utils/request';
import { Grid } from '@material-ui/core';

import { FormattedDate } from 'react-intl';

const useStyles = makeStyles(theme => ({
  root: {
    flex: 1,
    background: '#eaeff1',
  },
  content: {
    padding: theme.spacing(6, 4),
  },
  paper: {
    margin: '0 auto 20px',
    overflow: 'hidden',
    maxWidth: 936,
  },
  paperHeading: {
    margin: 0,
    padding: '10px',
    background: '#eaebeb',
    fontSize: '1.1rem',
  },
  searchBar: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  searchInput: {
    fontSize: theme.typography.fontSize,
  },
  block: {
    display: 'block',
  },
  addUser: {
    marginRight: theme.spacing(1),
  },
  contentWrapper: {
    margin: '10px 20px 10px',
  },
  contentText: {
    marginBottom: '0'
  },
  table: {
    
  },
  tableIcon: {
    float: 'right',
    width: '30px',
  },
  tableIconGreen: {
    color: '#00ff00',
  },
  tableIconRed: {
    color: '#ff0000',
  },
  latestTestImage: {
    margin: '20px',
    border: '1px solid #999',
    borderRadius: '5px',
  }
}));

export interface TestsProps {
  match: any;
}

export default function Tests(props: TestsProps) {
  const classes = useStyles(props);
  const { siteId } = props.match.params;
  const identity = useIdentityContext();
  const userId = identity.user && identity.user.id;
  const [site, setSite] = React.useState();
  const [tests, setTests] = React.useState([]);

  const request = useRequest();

  function createData(name: string, calories: number, fat: number, carbs: number, protein: number) {
    return { name, calories, fat, carbs, protein };
  }
  
  const rows = [
    createData('19/09/2019', 159, 6.0, 24, 4.0),
    createData('18/09/2019', 159, 6.0, 24, 4.0),
    createData('17/09/2019', 159, 6.0, 24, 4.0),
  ];  

  React.useEffect(() => {
    request(`/user/${userId}/site/${siteId}`).then(res => res.json()).then((siteData) => {
      setSite(siteData);
    });
    request(`/site/${siteId}/tests`).then(res => res.json()).then((tests) => {
      console.log(tests);
      setTests(tests);
    });
  }, [siteId, userId, identity]);

  return (
    <div className={classes.root}>
      {site && site.name !== '' && <div>
        <SubHeader title={`Site: ${site.name}`} tab={1} siteId={siteId} />
        <main className={classes.content}>
          <Paper className={classes.paper}>
            <h2 className={classes.paperHeading}>Most Recent Test {site && site.latestTest.testDate}</h2>
            {site && <Grid container>
              <Grid>
                <img className={classes.latestTestImage} src={site.latestTest.audits['final-screenshot'].details.data} width="200px" />
              </Grid>
              <Grid>
                <p>First Contentful Paint: {site.latestTest.audits['first-contentful-paint'].displayValue} </p>
                <p>First Meaningful Paint: {site.latestTest.audits['first-meaningful-paint'].displayValue} </p>
                <p>Time to interative: {site.latestTest.audits['interactive'].displayValue} </p>
                <p>Bootup time: {site.latestTest.audits['bootup-time'].displayValue} </p>
                <p>Total Network Requests: {site.latestTest.audits["network-requests"].details.items.length} </p>
              </Grid>
            </Grid>}
          </Paper>

          <Paper className={classes.paper}>
            <h2 className={classes.paperHeading}>All Tests</h2>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell><strong>Date</strong></TableCell>
                  <TableCell align="right">First Contentful Paint</TableCell>
                  <TableCell align="right">First Meaningful Paint</TableCell>
                  <TableCell align="right">Time to interative</TableCell>
                  <TableCell align="right">Bootup Time</TableCell>
                  <TableCell align="right">Total Network Requests</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {tests.map((test: any) => (
                  <TableRow key={test.createdAt}>
                    <TableCell component="th" scope="row">
                      <FormattedDate value={test.createdAt} />
                    </TableCell>
                    <TableCell align="right">{test.value.audits['first-contentful-paint'].displayValue}</TableCell>
                    <TableCell align="right">{test.value.audits['first-meaningful-paint'].displayValue}</TableCell>
                    <TableCell align="right">{test.value.audits['interactive'].displayValue}</TableCell>
                    <TableCell align="right">{test.value.audits['bootup-time'].displayValue}</TableCell>
                    <TableCell align="right">{test.value.audits['network-requests'].details.items.length}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Paper>
        </main>
      </div>}
    </div>
  );
}