import React from 'react';
import { IdentityModal } from 'react-netlify-identity-widget'

// Custom Components
import Helper from '../components/Helper';
import Header from '../components/MarketingHeader';

const LoggedOut: React.FC = (props) => {
  const [dialog, setDialog] = React.useState(false)

  return (
    <div>
      <Header />
      <Helper type='alignCenter'>
        <p>Coming Soon</p>
        <button className="btn" onClick={() => setDialog(true)}>
          LOG IN
        </button>
      </Helper>

      <IdentityModal
          showDialog={dialog}
          onCloseDialog={() => setDialog(false)}
          onLogin={() => setDialog(false)}
          onSignup={() => setDialog(false)}
          onLogout={() => setDialog(false)}
        />
    </div>
  );
}

export default LoggedOut;
        