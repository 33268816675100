import React from 'react';
import SubHeader from '../../components/SubHeader';
import { makeStyles } from '@material-ui/core/styles';
import { useIdentityContext } from 'react-netlify-identity-widget';

import useRequest from '../../utils/request';

const useStyles = makeStyles(theme => ({
  root: {
    flex: 1,
    background: '#eaeff1',
  },
  content: {
    padding: theme.spacing(6, 4),
  },
  paper: {
    margin: '0 auto 0',
    overflow: 'hidden',
    maxWidth: 936,
  },
  searchBar: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  searchInput: {
    fontSize: theme.typography.fontSize,
  },
  block: {
    display: 'block',
  },
  addUser: {
    marginRight: theme.spacing(1),
  },
  contentWrapper: {
    margin: '10px 20px 10px',
  },
  contentText: {
    marginBottom: '0'
  },
  table: {
    
  },
  tableIcon: {
    float: 'right',
    width: '30px',
  },
  tableIconGreen: {
    color: '#00ff00',
  },
  tableIconRed: {
    color: '#ff0000',
  }
}));

export interface SetupProps {
  match: any;
  onDelete: Function;
  history: any,
}

export default function Setup(props: SetupProps) {
  const classes = useStyles(props);
  const { siteId } = props.match.params;
  const identity = useIdentityContext();
  const userId = identity.user && identity.user.id;
  const [site, setSite] = React.useState({
    name: '',
    url: '',
  });
  
  const request = useRequest();

  React.useEffect(() => {
    request(`/user/${userId}/site/${siteId}`).then(res => res.json()).then((siteData) => {
      setSite(siteData);
    });
  }, [siteId, userId, identity]);

  function onClick() {
    const deleteConfirmation = window.confirm(`Are you sure you want to delete the site '${site.name}'?`);

    if (deleteConfirmation) {
      request(`/site/${siteId}`, {
        method: 'DELETE',
      }).then(res => res.text()).then(() => {
        props.onDelete();
        props.history.push(`/`);
      });
    }
  }

  return (
    <div className={classes.root}>
      {site && site.name !== '' && <div>
        <SubHeader title={`Site: ${site.name}`} tab={2} siteId={siteId} />
        <div className={classes.content}>
          <h1>Setup</h1>

          <p>Name: {site.name}</p>
          <p>URL: {site.url}</p>

          <button onClick={onClick}>Delete</button>
        </div>
      </div>}
    </div>
  );
}
        