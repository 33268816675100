import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Grid from '@material-ui/core/Grid';

import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import { IdentityModal } from 'react-netlify-identity-widget'
import { NavLink } from 'react-router-dom';

const lightColor = 'rgba(255, 255, 255, 0.7)';

const styles = (theme: Theme) =>
  createStyles({
    secondaryBar: {
      zIndex: 0,
    },
    menuButton: {
      marginLeft: -theme.spacing(1),
    },
    iconButtonAvatar: {
      padding: 4,
    },
    link: {
      textDecoration: 'none',
      color:  theme.palette.common.white,
      '&:hover': {
        color: lightColor,
        textDecoration: 'none',
      },
    },
    button: {
      borderColor: lightColor,
    },
    avatarMenu: {
      marginTop: '40px',
    },
    extraPadding: {
      paddingTop: '10px',
      paddingBottom: '52px',
    }
  });

interface HeaderProps extends WithStyles<typeof styles> {
  title: string;
  showMenu?: boolean;
  tab?: number;
  siteId?: string
}

function Header(props: HeaderProps) {
  const { classes } = props;
  const [dialog, setDialog] = React.useState(false)

  return (
    <React.Fragment>
      <AppBar
        component="div"
        className={classes.secondaryBar}
        color="primary"
        position="static"
        elevation={0}
      >
        <Toolbar className={props.showMenu === false ? classes.extraPadding : ''}>
          <Grid container alignItems="center" spacing={1}>
            <Grid item xs>
              <Typography color="inherit" variant="h5" component="h1">
                {props.title}
              </Typography>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      {props.showMenu !== false && <AppBar
        component="div"
        className={classes.secondaryBar}
        color="primary"
        position="static"
        elevation={0}
      >
        <Tabs value={props.tab || 0} textColor="inherit">
          <NavLink to={`/site/${props.siteId}`} className={classes.link}>
            <Tab textColor="inherit" label="Report" className={classes.link} />
          </NavLink>
          <NavLink to={`/site/${props.siteId}/tests`}  className={classes.link}>
            <Tab textColor="inherit" label="Test History" className={classes.link} />
          </NavLink>
          <NavLink to={`/site/${props.siteId}/setup`}  className={classes.link}>
            <Tab textColor="inherit" label="Setup" className={classes.link} />
          </NavLink>
        </Tabs>
      </AppBar>}

      <IdentityModal
          showDialog={dialog}
          onCloseDialog={() => setDialog(false)}
          onLogin={() => setDialog(false)}
          onSignup={() => setDialog(false)}
          onLogout={() => setDialog(false)}
        />
    </React.Fragment>
  );
}

export default withStyles(styles)(Header);
